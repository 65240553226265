/**
 * Breakpoint component used by include-media vendor library.
 */
@use 'sass:map';
/**
 * Extracts nested keys from given map.
 * Example: deep-get($some-map, some, nested, keys);
 */
/**
* Default project breakpoints - [em]
*/
/**
 * Breakpoint component used by include-media vendor library.
 */
@use 'sass:map';
/**
 * Extracts nested keys from given map.
 * Example: deep-get($some-map, some, nested, keys);
 */
/**
* Default project breakpoints - [em]
*/
/**
 * Breakpoint component used by include-media vendor library.
 */
@use 'sass:map';
/**
 * Extracts nested keys from given map.
 * Example: deep-get($some-map, some, nested, keys);
 */
/**
* Default project breakpoints - [em]
*/
/**
 * Breakpoint component used by include-media vendor library.
 */
@use 'sass:map';
/**
 * Extracts nested keys from given map.
 * Example: deep-get($some-map, some, nested, keys);
 */
/**
* Default project breakpoints - [em]
*/
/**
 * Breakpoint component used by include-media vendor library.
 */
@use 'sass:map';
/**
 * Extracts nested keys from given map.
 * Example: deep-get($some-map, some, nested, keys);
 */
/**
* Default project breakpoints - [em]
*/
/**
 * Gap between columns (all CSS units allowed) [Example: 1.5rem]
 * @see https://developer.mozilla.org/en-US/docs/Web/CSS/column-gap
 */
/**
 * @see https://developer.mozilla.org/en-US/docs/Web/CSS/column-rule
 */
.cs-paragraph {
  column-gap: 6rem;
  column-rule: 1px solid #dcdcdc;
  margin: 0 0 3rem; }
  @media (min-width: 48em) {
    .cs-paragraph {
      margin: 0 0 4rem; } }
  .cs-paragraph--skip-spacing {
    margin: 0; }
  .cs-paragraph img {
    height: auto; }
  @media (min-width: 48em) {
    .cs-paragraph--cols-2, .cs-paragraph--cols-3, .cs-paragraph--cols-4 {
      column-count: 2; }
      .cs-paragraph--cols-2 li, .cs-paragraph--cols-3 li, .cs-paragraph--cols-4 li {
        width: 100%; }
      .cs-paragraph--cols-2 img, .cs-paragraph--cols-3 img, .cs-paragraph--cols-4 img {
        display: block;
        max-width: 100%; } }
  @media (min-width: 64em) {
    .cs-paragraph--cols-3, .cs-paragraph--cols-4 {
      column-count: 3; } }
  @media (min-width: 80em) {
    .cs-paragraph--cols-4 {
      column-count: 4; } }
  .cs-paragraph--mode-optimal {
    max-width: 40em;
    margin-left: auto;
    margin-right: auto;
    column-count: initial; }
